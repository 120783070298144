import React from "react";

import styled from "styled-components";

import { Container } from "styled-bootstrap-grid";
import { HeadingTwo, TextBig } from "components/shared/Typography";
import LazyImage from "components/shared/LazyImage";

const Wrapper = styled.div`
  position: relative;
  margin: 45px 0 60px 0;

  & .masonry-grid {
    display: flex;
    margin-left: -30px;
    width: auto;
  }
  & .masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
  }

  & .masonry-grid_column > div {
    background: grey;
    margin-bottom: 30px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 40px 0;
  text-align: center;
  & ${HeadingTwo} {
    display: inline-flex;
    justify-content: center;
    margin: 0 0 5px 0;
    &:before {
      left: unset;
    }
  }
`;

const HeadingInner = styled.span``;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 3 - 20px);
  height: 400px;
  margin: 10px;
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1100px) {
    width: calc(50% - 20px);
  }

  @media screen and (max-width: 770px) {
    width: 100%;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  @media screen and (max-width: 770px) {
    margin: 0;
  }
`;

const GalleryGrid = (props) => {
  return (
    <Wrapper>
      <Header>
        <HeadingTwo>
          <HeadingInner dangerouslySetInnerHTML={{ __html: props.heading }} />
        </HeadingTwo>
        {props.text && (
          <TextBig
            color="black"
            dangerouslySetInnerHTML={{ __html: props.text }}
          />
        )}
      </Header>
      <Container>
        <Grid>
          {props.images.map((image, index) => {
            return (
              <ImageWrapper key={index}>
                <LazyImage src={image} cover="cover" />
              </ImageWrapper>
            );
          })}
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default GalleryGrid;
