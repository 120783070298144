import styled, { css, keyframes } from 'styled-components';
import iconSend from 'assets/icon-send.svg';

const spin = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const Button = styled.button`
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 18px 35px;
  color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ theme }) => theme.colors.primary};
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  background-image: url(${iconSend});
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out,
    border 300ms ease-in-out;
  width: ${props => (props.full ? '100%' : 'auto')};
  &:hover {
    background-color: #fff;
  }
  ${({ submit }) =>
    submit &&
    css`
      position: relative;
      height: 52px;
      pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 24px;
        border: 3px solid ${({ theme }) => theme.colors.secondary};
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-radius: 50%;
        background: none;
        opacity: ${({ disabled }) => (disabled ? '1' : '0')};
        animation: ${spin} 1s ease infinite;
      }
      &:after {
        display: none;
      }
    `}
`;
