import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TextSmall } from 'components/shared/Typography';

import checkmark from 'assets/checkmark.svg';

const Wrapper = styled.div`
    position: relative;
    top: 8px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin: 0 0 15px 0;
    height: 60px;
    overflow-y: scroll;
    & ${TextSmall} {
        color: #fff;
        padding: 2px 40px 0 0;
        width: calc(100% - 100px);
        & a {
            color: #fff;
            font-weight: 700;
        }
    }
`;

const StyledCheckbox = styled.button`
    display: block;
    margin: 0 20px 0 0;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
`;

const StyledCheckboxInner = styled.span`
    position: relative;
    margin: 0;
    height: 20px;
    width: 20px;
    display: block;
    background-color: #c4cde3;
    border-radius: 40px;
    cursor: pointer;
    &:before {
        content: '';
        display: block;
        left: 0;
        top: 0;
        height: 20px;
        width: 20px;
        border-radius: 20px;
        background: url(${checkmark}) no-repeat center / cover;
        opacity: ${(props) => (props.checked ? '1' : '0')};
        transition: opacity 200ms ease-in-out;
        background-size: 12px;
    }
`;

const StyledCheckboxInput = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    z-index: -999;
`;

const More = (props) => {
    let [checked, setChecked] = useState(false);

    useEffect(() => {
        if (checked) props.setValue('Tak');
        else props.setValue('Nie');
    }, [checked]);

    return (
        <Wrapper>
            <StyledCheckbox
                onClick={(e) => {
                    e.preventDefault();
                    setChecked(!checked);
                }}
            >
                <StyledCheckboxInner checked={checked} />
                <StyledCheckboxInput value={checked} name={props.name} />
            </StyledCheckbox>
            <TextSmall
                dangerouslySetInnerHTML={{
                    __html: `${props.consent}`,
                }}
            />
        </Wrapper>
    );
};

export default More;
