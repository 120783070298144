import React, { useState } from 'react';
import styled from 'styled-components';

import angleDown from 'assets/angleDown.svg';

const Wrapper = styled.div`
  position: relative;
  margin: 0 0 10px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0;
  outline: 0;
  border: 0;
  border-bottom: 1px solid ${({ error }) => (error ? '#ed454d' : '#c4cde3')};
  border-radius: 0;
  background: transparent;
  font-size: 14px;
  color: ${({ error }) => (error ? '#ed454d' : '#fff')};
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    width: 20px;
    height: 20px;
    background: url(${angleDown}) no-repeat center / 12px;
    transform: ${(props) => (props.open ? 'rotate(-180deg)' : 'rotate(0)')};
    transition: transform 300ms ease-in-out;
  }
`;

const List = styled.ul`
  position: absolute;
  left: 0;
  top: 64px;
  width: 100%;
  padding: 10px 20px;
  margin: 0;
  max-height: 240px;
  overflow-y: scroll;
  background-color: #c4cde3;
  z-index: ${(props) => (props.open ? '20' : '-999')};
  display: ${(props) => (props.open ? 'block' : 'none')};
`;
const ListItem = styled.li`
  padding: 12px 0;
  font-size: 14px;
  line-height: 1;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  &:last-of-type {
    border-bottom: 0;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const HiddenInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  z-index: -999;
`;

const Dropdown = (props) => {
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState(null);

  const handleOption = (value) => () => {
    setOption(value);
    setOpen(false);
    props.setValue(value);
  };

  const toggle = () => setOpen(!open);

  return (
    <Wrapper>
      <HiddenInput name={props.name} value={option} />
      <Header error={props.error} open={open} onClick={toggle}>
        {props.error ? props.error : option || props.options[0]}
      </Header>
      <List open={open}>
        {props.options &&
          props.options.slice(1).map((option, index) => {
            return (
              <ListItem onClick={handleOption(option)} key={index}>
                {option}
              </ListItem>
            );
          })}
      </List>
    </Wrapper>
  );
};

export default Dropdown;
