import React from 'react';
import styled, { css } from 'styled-components';

const Label = styled.label`
    position: absolute;
    transform: translate(0, 26px) scale(1);
    font-size: 16px;
    padding: 0;
    pointer-events: none;
    transform-origin: top left;
    transition: all 0.2s ease-out;
    color: ${({ error }) => (error ? '#ed454d' : '#fff')};
    ${(props) =>
        props.active &&
        css`
            transform: translate(0, 12px) scale(0.85);
        `};
`;

const InputDOM = styled.input`
    width: 100%;
    height: 64px;
    padding: 22px 0 0 0;
    outline: 0;
    border: 0;
    border-bottom: 1px solid ${({ error }) => (error ? '#ed454d' : '#c4cde3')};
    border-radius: 0;
    background: transparent;
    font-size: 14px;
    color: #fff;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s !important;
        -webkit-text-fill-color: #fff !important;
    }
`;

const Wrapper = styled.div`
    margin: 0 0 10px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    &:focus-within {
        & ${Label} {
            transform: translate(0, 12px) scale(0.85);
        }
    }
`;

const Input = ({
    label,
    required,
    value,
    error,
    name,
    touched,
    handleChange,
    handleBlur,
}) => {
    return (
        <Wrapper>
            {label && (
                <Label error={error} active={value}>
                    {error || `${label}${required ? '*' : ''}`}
                </Label>
            )}
            <InputDOM
                active={value}
                value={value}
                error={error}
                onChange={handleChange}
                onBlur={handleBlur}
                name={name}
                touched={touched}
            />
        </Wrapper>
    );
};

export default Input;
