import React, { useState } from "react";
import styled from "styled-components";
import md5 from "md5";

import { Container, Row, Col } from "styled-bootstrap-grid";
import Input from "components/shared/form/Input";
import More from "components/shared/form/More";
import Dropdown from "components/shared/form/Dropdown";
import { Button } from "components/shared/Buttons";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";

const Thx = styled.section`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  height: 100%;
  width: 100%;
  background-color: rgba(65, 96, 164, 0.9);
  opacity: ${({ thankYou }) => (thankYou ? "1" : "0")};
  z-index: ${({ thankYou }) => (thankYou ? "11" : "-50")};
  transition: opacity 300ms ease-in-out, top 1ms ease-in-out 300ms;
`;
const ThxHeading = styled.h3`
  display: block;
  text-align: center;
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 34px;
  font-weight: 400;
  line-height: 1.25;
  color: #fff;
  & strong {
    display: block;
    font-weight: 700;
  }
`;

const ThxContent = styled.p`
  position: relative;
  margin: 20px 0 0 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25;
  color: #fff;
`;

const ButtonWrapper = styled.div`
  margin: 12px 0 0 0;
`;

const contactSchema = Yup.object().shape({
  name: Yup.string().required("Proszę o podanie imienia"),
  phone: Yup.string()
    .min(9, "Numer telefonu jest nieprawidłowy")
    .matches(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
      "Numer telefonu jest nieprawidłowy"
    )
    .required("Proszę podać numer telefonu"),
  region: Yup.string().required("Proszę o wybranie województwa"),
});

const FormHeader = styled.div`
  @media screen and (max-width: 991px) {
    margin: 0 0 15px 0;
  }
`;

const FormSubheading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 1.25;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};

  @media screen and (max-width: 1310px) {
    font-size: 22px;
  }
`;

const lead = ({ name, email, phone, id_user, consentContent }) => {
  const login = `salesbook_api`;
  const password = `28046085a33d5`;
  const authorize = `${login}:${password}`;
  const token = `Basic ${btoa(authorize)}`;
  let id_client = false;

  const formDataClient = new FormData();
  formDataClient.append(
    "email",
    `lead-${phone.replace(/\s+/g, "")}@larssonnobel.pl`
  );
  formDataClient.append("name", name);
  formDataClient.append("phone", phone);
  formDataClient.append("id_user", id_user); //przypisanie klienta do opienkuna (id)
  formDataClient.append("agreements", consentContent);

  axios
    .get(
      `https://larssonnobel-api.salesbook-ent.com/api/v1/persons/find?term=${phone.replace(
        /\s+/g,
        ""
      )}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      console.log(response);

      if (response.data.data.list.length >= 1) {
        id_client = response.data.data.list[0].id;
      }

      if (id_client === false) {
        axios
          .post(
            `https://larssonnobel-api.salesbook-ent.com/api/v1/persons/save`,
            formDataClient,
            {
              headers: {
                Authorization: token,
              },
            }
          )
          .then((response) => {
            console.log(response);

            id_client = response.data.data.id;

            const formDataThread = new FormData();
            formDataThread.append(
              "source",
              "Landing Page – https://larssonnobel.com/"
            );
            formDataThread.append("id_customer", id_client);
            formDataThread.append("id_user", id_user);
            formDataThread.append("status", "ODRZUCONE");

            axios
              .post(
                `https://larssonnobel-api.salesbook-ent.com/api/v1/threads/create`,
                formDataThread,
                {
                  headers: {
                    Authorization: token,
                  },
                }
              )
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const formDataThread = new FormData();
        formDataThread.append(
          "source",
          "Landing Page – https://larssonnobel.com/"
        );
        formDataThread.append("id_customer", id_client);
        formDataThread.append("id_user", id_user);
        formDataThread.append("status", "ODRZUCONE");

        axios
          .post(
            `https://larssonnobel-api.salesbook-ent.com/api/v1/threads/create`,
            formDataThread,
            {
              headers: {
                Authorization: token,
              },
            }
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
};

const Contact = (props) => {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [formMessage, setFormMessage] = useState({ text: "", error: false });
  const [thankYou, setThankYou] = useState(false);

  const voivodeships = [
    "Wybierz województwo",
    "Dolnośląskie",
    "Kujawsko-Pomorskie",
    "Lubelskie",
    "Lubuskie",
    "Łódzkie",
    "Małopolskie",
    "Mazowieckie",
    "Opolskie",
    "Podkarpackie",
    "Podlaskie",
    "Pomorskie",
    "Śląskie",
    "Świętokrzyskie",
    "Warmińsko-Mazurskie",
    "Wielkopolskie",
    "Zachodniopomorskie",
  ];

  return (
    <Container>
      <Formik
        initialValues={{
          name: "",
          phone: "",
          region: "",
          type: "Dla domu",
          consentContent: props.consent,
          consentValue: false,
        }}
        validationSchema={contactSchema}
        onSubmit={(
          { name, phone, region, consentContent, consentValue },
          { setSubmitting, resetForm }
        ) => {
          (async () => {
            let params = {
              name: name,
              phone: phone,
              url: window.location.href,
              region: region,
              source: "Strona główna",
              consentContent: consentContent,
              consentValue: consentValue,
            };

            try {
              setSubmitting(true);
              const { data } = await axios.get(
                `https://larsson-nobel.indigital.guru/mailer/mailer.php`,
                {
                  params,
                }
              );
              setSubmitting(false);
              if (data.code !== 200) {
                throw new Error(data.message);
              }

              setFormMessage({
                text: data.message,
                error: false,
              });

              setThankYou(true);

              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "Lead",
                leadid: md5(params.phone),
                wojewodztwo: params.region,
                instalacja: params.type,
              });
              lead({
                name: params.name,
                phone: params.phone,
                id_user: "25",
                consentContent: JSON.stringify(params.consentContent),
              });

              setTimeout(() => {
                setFormMessage({ text: "", error: false });
                resetForm();
                setThankYou(false);
              }, 5000);
            } catch (err) {
              setSubmitting(false);
              setFormMessage({
                text: "Coś poszło nie tak!",
                error: true,
              });

              setTimeout(() => {
                setFormMessage({ text: "", error: false });
              }, 2500);
            }
          })();
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form
            onSubmit={(formValues) => {
              handleSubmit(formValues);
              setSubmitClicked(true);
            }}
          >
            <Row>
              <Col lg="8" order="1" lgOrder="1">
                <FormHeader>
                  <FormSubheading
                    dangerouslySetInnerHTML={{ __html: props.heading }}
                  />
                </FormHeader>
              </Col>
              <Col lg="4" order="5" lgOrder="2">
                <More
                  consent={props.consent}
                  name="consent"
                  setValue={(value) => setFieldValue("consentValue", value)}
                />
              </Col>
              <Col lg="3" order="2" lgOrder="3">
                <Input
                  required
                  label="Twoje Imię"
                  name="name"
                  value={values.name}
                  error={submitClicked && errors.name}
                  touched={errors.touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Col>
              <Col lg="3" order="3" lgOrder="4">
                <Input
                  required
                  label="Telefon"
                  name="phone"
                  value={values.phone}
                  error={submitClicked && errors.phone}
                  touched={errors.touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Col>
              <Col lg="3" order="4" lgOrder="5">
                <Dropdown
                  label="Województwo"
                  name="region"
                  options={voivodeships}
                  error={submitClicked && errors.region}
                  setValue={(value) => setFieldValue("region", value)}
                />
              </Col>
              <Col lg="3" order="6" lgOrder="6">
                <ButtonWrapper>
                  <Button submit full disabled={isSubmitting} type="submit">
                    {!isSubmitting && "Wyślij"}
                  </Button>
                </ButtonWrapper>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <Thx thankYou={thankYou}>
        <ThxHeading>
          Dziękujemy za <br />
          przesłanie wiadomości
        </ThxHeading>
        <ThxContent>
          Skontaktujemy się z Państwem najszybciej jak to możliwe.
        </ThxContent>
      </Thx>
    </Container>
  );
};

export default Contact;
