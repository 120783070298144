import React from "react";
import styled, { css } from "styled-components";
import { Container } from "styled-bootstrap-grid";

import { Text } from "components/shared/Typography";
import Phone from "components/Phone";

//Assets
import logo from "assets/logo-color.svg";
import quoteBg from "assets/quote-bg.svg";
import iconPdf from "assets/iconPdf.svg";
import pdfOffer from "assets/oferta-larsson-nobel.pdf";

const Wrapper = styled.div`
  padding: 0 0 90px 0;
  background-color: ${({ theme }) => theme.colors.secondary};

  @media screen and (max-width: 991px) {
    padding: 0 0 95px 0;
  }
`;

const WrapperInner = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 992px) {
    background-image: url(${quoteBg});
    background-position: right 83px;
    background-repeat: no-repeat;
    background-size: 180px;
  }
`;

const BrandWrapper = styled.p`
  position: absolute;
  left: 20px;
  top: 0;
  margin: 0;
  padding: 18px 20px;
  background-color: #fff;
  display: inline-block;

  @media screen and (max-width: 991px) {
    left: unset;
  }
`;

const BrandImage = styled.img`
  height: 45px;
`;

const TextBlock = styled.blockquote`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  margin: 166px 120px 0 120px;

  @media screen and (max-width: 991px) {
    margin: 166px 20px 0 0;
  }
`;

const TextBlockInner = styled.p`
  font-size: 24px;
  line-height: 1.75;
  color: #fff;
  max-width: 920px;
  font-style: italic;
  font-weight: 700;
`;

const Author = styled(Text)`
  margin-top: 78px;
  position: relative;
  color: #fff;
  display: inline-flex;
  align-items: center;
  &:before {
    content: "";
    position: absolute;
    right: 0;
    width: 100vw;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.primary};
    z-index: 1;
  }

  @media screen and (max-width: 991px) {
    margin: 70px 0 80px auto;
  }
`;

const AuthorInner = styled.span`
  position: relative;
  padding: 0 30px;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  @media screen and (max-width: 991px) {
    padding: 0 0 0 20px;
  }
`;

const PdfDownloader = styled.a`
  position: relative;
  display: inline-flex;
  font-size: 24px;
  line-height: 1.75;
  color: ${({ theme }) => theme.colors.primary};
  font-style: normal;
  font-weight: 700;
  margin: 0 auto 30px 0;
  &:before {
    content: "";
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 0 15px 0 0;
    background-color: ${({ theme }) => theme.colors.primary};
    background-image: url(${iconPdf});
    background-size: 22px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100px;
  }
  @media screen and (max-width: 991px) {
    font-size: 20px;
  }
`;

const PhoneWrapper = styled.div`
  @media screen and (min-width: 992px) {
    position: absolute;
    right: 20px;
    bottom: -10px;
  }
`;

const Quote = (props) => {
  return (
    <Wrapper>
      <Container css={WrapperInner}>
        <BrandWrapper>
          <BrandImage src={logo} />
        </BrandWrapper>
        <TextBlock>
          {props.downloads?.map((download, index) => (
            <PdfDownloader
              target="_blank"
              href={download.link}
              rel="nofollow"
              key={index}
            >
              {download.text}
            </PdfDownloader>
          ))}
          <TextBlockInner dangerouslySetInnerHTML={{ __html: props.content }} />
          <Author>
            <AuthorInner>{props.author}</AuthorInner>
          </Author>
        </TextBlock>
        {props.phone && (
          <PhoneWrapper>
            <Phone number={props.phone} />
          </PhoneWrapper>
        )}
      </Container>
    </Wrapper>
  );
};

export default Quote;
