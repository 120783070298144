import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ meta, title }) {
  meta = [];

  const {
    wpgraphql: {
      page: { seo },
    },
  } = useStaticQuery(
    graphql`
      query querySEO {
        wpgraphql {
          page(id: "/strona-glowna/", idType: URI) {
            seo {
              metaDesc
              opengraphDescription
              opengraphTitle
              opengraphSiteName
              opengraphImage {
                sourceUrl
              }
            }
          }
        }
      }
    `
  );

  const ogImage = seo.opengraphImage
    ? { property: `og:image`, content: seo.opengraphImage.sourceUrl }
    : {};

  return (
    <Helmet
      title={title}
      titleTemplate={`%s - ${seo.opengraphSiteName}`}
      meta={[
        {
          name: `robots`,
          content: `index, follow`,
        },
        {
          name: `description`,
          content: seo.metaDesc || seo.opengraphDescription,
        },
        {
          property: `og:title`,
          content: seo.opengraphTitle,
        },
        {
          property: `og:description`,
          content: seo.opengraphDescription || seo.metaDesc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: `formularz.larssonnobel`,
        },
        {
          name: `twitter:title`,
          content: seo.opengraphTitle,
        },
        {
          name: `twitter:description`,
          content: seo.metaDesc || seo.opengraphDescription,
        },
        ogImage,
      ].concat(meta)}
    />
  );
}

export default Seo;
