import React from "react";
import styled from "styled-components";

import Contact from "components/Contact";
import LazyImage from "components/shared/LazyImage";

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  max-height: 900px;

  display: flex;
  flex-direction: column;
`;

const Image = styled(LazyImage)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: 991px) {
    display: none;
  }
`;

const MobileImage = styled(LazyImage)`
  display: none;
  position: absolute !important;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: 991px) {
    display: block;
  }
`;

const BackgroundWrapper = styled.div`
  position: relative;
  flex: 1;
  display: block;

  &:before {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.05);
    z-index: 1;
  }
`;

const Bar = styled.div`
  position: relative;

  width: 100%;
  min-height: fit-content;
  padding: 30px 105px;
  background-color: ${({ theme }) => theme.colors.secondary};
  z-index: 999;

  @media screen and (max-width: 991px) {
    padding: 30px 0;
  }
`;

const BannerMain = ({ heading, consent, image, imageMobile }) => {
  return (
    <Wrapper>
      <BackgroundWrapper>
        <Image src={image} cover="cover" />
        <MobileImage src={imageMobile} cover="cover" />
      </BackgroundWrapper>
      <Bar>
        <Contact heading={heading} consent={consent} />
      </Bar>
    </Wrapper>
  );
};

export default BannerMain;
