import React from 'react';
import styled from 'styled-components';

import iconPhone from 'assets/icon-phone.svg';

const Wrapper = styled.a`
    display: inline-flex;
    align-items: center;
`;

const IconWrapper = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary};
    /* box-shadow: 0 0 0 rgba(255, 204, 27, 0.4); */
    animation: pulse 2s infinite;
`;

const Icon = styled.img`
`;

const Text = styled.p`
    margin: 0 0 0 15px;
    padding: 0;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
`;

const Phone = (props) => {
  return (
    <Wrapper href={`tel: ${props.number}`}>
      <IconWrapper>
        <Icon src={iconPhone} />
      </IconWrapper>
      <Text>{props.number}</Text>
    </Wrapper>
  );
};

export default Phone;