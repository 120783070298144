import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';

const LazyImage = styled(Img).attrs((props) => ({
  fluid: props.src,
  objectPosition: props.objectPosition || '50% 50%',
  objectFit: props.cover ? 'cover' : 'contain',
  loading: 'lazy',
}))`
  min-height: ${(props) =>
    props.minHeight ? `${props.minHeight}px` : 'initial'};
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : 'initial')};
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? `${props.height}px` : '100%')};
  max-height: 100%;
  max-width: 100%;
`;

export default LazyImage;