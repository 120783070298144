import React from "react";
import styled from "styled-components";
import { Container } from "styled-bootstrap-grid";

import { HeadingTwo, TextBig } from "components/shared/Typography";

const Wrapper = styled.div`
  margin: 45px 0;
`;

const Header = styled.div`
  text-align: center;

  & ${HeadingTwo} {
    margin: 0 0 20px 0;
  }

  & ${TextBig} {
    max-width: 1000px;
    margin: 0 auto;
  }
`;

const Process = (props) => {
  return (
    <Wrapper>
      <Container>
        <Header>
          <HeadingTwo>{props.heading}</HeadingTwo>
          <TextBig
            color="black"
            dangerouslySetInnerHTML={{ __html: props.text }}
          />
        </Header>
      </Container>
    </Wrapper>
  );
};

export default Process;
