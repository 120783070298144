import styled, { css } from 'styled-components';

const handleColor = (color) => {
    switch (color) {
        case 'yellow':
            return ({ theme }) => theme.colors.primary;
        case 'white':
            return '#fff';
        case 'black':
            return '#000';
        default:
            return ({ theme }) => theme.colors.secondary;
    }
};

const Heading = css`
    position: relative;
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: 1.25;

    & strong {
        font-weight: 700;
    }
`;

export const HeadingTwo = styled.h2`
    ${Heading}

    display: flex;
    justify-content: center;
    font-family: 'EFNWidok', 'Open Sans', sans-serif;
    padding: 25px 0 0 0;
    font-size: 42px;
    color: ${({ color }) => handleColor(color)};

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        width: 80px;
        height: 8px;
        background-color: ${({ theme }) => theme.colors.primary};
    }

    @media screen and (max-width: 991px) {
        display: block !important;
        text-align: center;
        &:before {
            display: none;
        }
    }
`;

export const TextSmall = styled.p`
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    & strong {
        font-weight: 700;
    }
`;

export const Text = styled.p`
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    & strong {
        font-weight: 700;
    }
`;

export const TextBig = styled.p`
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    color: ${({ color }) => handleColor(color)};
    & strong {
        font-weight: 700;
    }
`;
